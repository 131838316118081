<template>
  <div class="dictionary-entry" :class="{ loading: loading && selectedLemma }">
    <LoadingSpinner v-if="loading" />
    <EmptyMessage v-if="!selectedLemma">
      Select a Greek word to load the matching dictionary entry
    </EmptyMessage>
    <div
      v-else-if="entries?.length > 0"
      class="entry-container"
      v-html="content"
      @click="handleClick"
    />
    <EmptyMessage v-else>No entries found for "{{ selectedLemma }}"</EmptyMessage>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import mixins from '@/mixins';
  import { DEFAULT_OSIS_REF, DEFAULT_GROUP_KEY } from '@/store/constants';
  import LoadingSpinner from './components/LoadingSpinner.vue';
  import EmptyMessage from './components/EmptyMessage.vue';

  export default {
    name: 'DictionaryEntryWidget',
    mixins: [mixins.InputDataMixin, mixins.OutputsMixin],
    props: {
      doric: {
        inputs: {
          label: 'Abbott-Smith Dictionary Entries',
          selectedLemma: {
            groupKey: DEFAULT_GROUP_KEY,
            // FIXME: value means a different thing when it is a local input versus a non-local input
            value: 'selectedLemma',
          },
        },
        outputs: {
          osisRef: DEFAULT_OSIS_REF,
          selectedLemma: null,
        },
      },
    },
    computed: {
      content() {
        // TODO: Determine if we have a pattern for applying XSLT
        // to this content
        return this.entries.map(entry => entry.xmlContent).join('\n');
      },
      loading() {
        return this.$apollo.queries.entries.loading;
      },
    },
    apollo: {
      entries: {
        query: gql`
          query DictionaryEntries($filters: DictionaryEntryFilter!) {
            dictionaryEntries(filters: $filters) {
              id
              uri
              xmlContent
            }
          }
        `,
        variables() {
          return {
            filters: {
              headword: { iExact: this.selectedLemma },
              dictionary: { uri: { exact: 'dictionaries:abbott-smith-lexicon' } },
            },
          };
        },
        skip() {
          return !this.selectedLemma;
        },
        update(data) {
          return data.dictionaryEntries;
        },
      },
    },
    methods: {
      handleClick(e) {
        if (e.target.tagName === 'REF') {
          e.preventDefault();

          const osisRef = e.target.getAttribute('osisref');
          if (osisRef) {
            this.outputs.osisRef.value = osisRef;
            this.outputs.selectedLemma.value = this.selectedLemma;
            // vuex history here?
            // DDN: Jonathan wants to be able to have "history"
            this.submit();
          }
        }
        if (e.target.tagName === 'FOREIGN' && e.target.parentElement.tagName === 'REF') {
          e.preventDefault();
          this.outputs.selectedLemma.value = e.target.textContent;
          this.submit();
        }
      },
    },
    components: {
      LoadingSpinner,
      EmptyMessage,
    },
  };
</script>

<style scoped lang="scss">
  .dictionary-entry {
    // TODO: Expose style options using CSS config vars?
    :deep(.entry-container) {
      font-family: 'Gentium Book Plus';
      line-height: 1.5;
      note {
        display: none;
      }
      gloss {
        font-style: italic;
      }
      form {
        display: inline;
        > orth {
          font-weight: 700;
          font-size: 1.2rem;
        }
        > foreign {
          font-weight: initial;
          font-style: italic;
        }
      }
      ref {
        color: #666;
        text-decoration: underline;
        cursor: pointer;
      }
      foreign {
        font-weight: 700;
      }
    }
  }
</style>
